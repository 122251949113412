import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { Title, Text, GatsbyImage } from 'elements';
import { ContentSection } from 'components';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { HydroponicTechSection, FeaturesList, Feature } from './HydroponicSection.styled';

import breakPoints from 'theme/Media';

const HydroponicSection = ({ id, title, body, percentages: bullets }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "4f41rmkHZ6if7N0z7S0qjw" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const backgroundImages = data?.contentfulSectionBlocks?.mediaBackground?.map?.((image, index) => {
          return {
            image: image?.gatsbyImageData,
            alt: image?.description,
            media: index === 0 ? breakPoints.large_break : index === 1 ? breakPoints.medium_break : null,
            isDefault: index === 2,
          };
        });
        return (
          <HydroponicTechSection key={id}>
            <ContentSection>
              <ContentWrapper>
                <Title modifiers={['inverted', 'secondarySmall']} as='h3' content={title} />
                <Text modifiers='inverted' content={body} />
                <FeaturesList>
                  {bullets.map((bullet) => {
                    const id = bullet?.sys.id;
                    const text = bullet?.fields?.title;
                    return (
                      <Feature key={id}>
                        <Text modifiers='inverted' as='span' content={text} />
                      </Feature>
                    );
                  })}
                </FeaturesList>
                {!!backgroundImages?.length && <GatsbyImage breakPointImages={backgroundImages} />}
              </ContentWrapper>
            </ContentSection>
          </HydroponicTechSection>
        );
      }}
    />
  );
};

HydroponicSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  percentages: PropTypes.array,
};

export default HydroponicSection;
