import React from 'react';
import PropTypes from 'prop-types';

import { useStopLoading } from 'utils/hooks';

import { HowItWorksStyled } from './HowItWorks.styled';

import { pageSpecificMap } from './SectionsRenderer';
import { SectionProvider } from 'components';

const HowItWorksLanding = ({ content }) => {
  useStopLoading(true);

  const fields = content.fields;

  return (
    <HowItWorksStyled>
      <SectionProvider sectionContent={fields.sections} customSectionMap={pageSpecificMap} />
    </HowItWorksStyled>
  );
};

HowItWorksLanding.propTypes = {
  content: PropTypes.object,
};

export default HowItWorksLanding;
