import React from 'react';
import { useDispatch } from 'react-redux';

import HowItWorks from 'src/staticPages/HowItWorks';
import OurMission from 'src/staticPages/ourMission/OurMission';
import FarmstandProduct from 'src/staticPages/farmstand-product/FarmstandProduct';
import Seedlings from 'src/staticPages/Seedlings';
import Home from 'src/staticPages/home/Home';
import Sandbox from 'src/staticPages/contentfulSandbox';
import RecipeLanding from 'src/staticPages/recipeLanding';
import ReturnLanding from 'src/staticPages/returnLanding/ReturnLanding';
import AssemblyGuide from 'src/staticPages/assemblyGuide/AssemblyGuide';
import HarvestLandingPage from 'app/pages/harvest-plan-product/HarvestPlanProduct';
import { LgType } from 'constants/LgType';

import { MarketingContainer, SectionProvider } from 'components';

import paths from 'constants/paths';
import farmstandSizes from 'constants/farmstandSizes';
import { getContentfulEntryLgType } from 'utils/contentful-utils';

import { setIsLoading } from 'reduxState/appSettings';

const marketingComponentMap = Object.values(paths.marketing).reduce((acc, path) => {
  acc[path] = MarketingContainer;
  return acc;
}, {});

const pageComponentMap = {
  [paths.EXPERIENCE]: HowItWorks,
  [paths.OUR_MISSION]: OurMission,
  [paths.FARMSTAND]: FarmstandProduct,
  [paths.SEEDLING_LIBRARY]: Seedlings,
  [`${paths.FARMSTAND}/${farmstandSizes[12]}`]: FarmstandProduct,
  [`${paths.FARMSTAND}/${farmstandSizes[18]}`]: FarmstandProduct,
  [`${paths.FARMSTAND}/${farmstandSizes[24]}`]: FarmstandProduct,
  [`${paths.FARMSTAND}/${farmstandSizes[30]}`]: FarmstandProduct,
  [`${paths.FARMSTAND}/${farmstandSizes[36]}`]: FarmstandProduct,
  [paths.HOME]: Home,
  [paths.RECIPES]: RecipeLanding,
  [paths.RETURN_LANDING]: ReturnLanding,
  [paths.ASSEMBLY_GUIDE_PAGE]: AssemblyGuide,
  [paths.SANDBOX_LANDING]: Sandbox,
  [LgType.HARVEST.LANDING]: HarvestLandingPage,
  ...marketingComponentMap,
};

const LandingPageDataProvider = ({ pageContext: { content, slug, farmSize } }) => {
  const dispatch = useDispatch();
  const lgType = getContentfulEntryLgType(content);
  const Component = lgType ? pageComponentMap[lgType] : pageComponentMap[slug];
  if (!Component) {
    dispatch(setIsLoading(false));
    return <SectionProvider sectionContent={content.fields.sections} />;
  }
  if (farmSize) {
    return <Component content={content} farmSize={farmSize} />;
  } else {
    return <Component content={content} />;
  }
};

export default LandingPageDataProvider;
