import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const SeedlingLandingStyled = styled.div.attrs({
  className: 'SeedlingLandingStyled',
})`
  position: relative;

  .ContentSection {
    background-color: white;

    .SectionInner {
      max-width: 33.75rem;
      margin: auto;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        max-width: 62rem;
        margin: auto;
      }
    }
  }
`;

export const PageTopInfo = styled.section.attrs({
  className: 'PageTopInfo',
})`
  .Title {
    margin-bottom: 1.5rem;
  }

  .Text {
    max-width: 55rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 4rem;
      max-width: 40rem;
      margin: 0 auto 2rem;
    }
  }
`;

export const FiltersSortingWrapper = styled.section.attrs({
  className: 'FiltersSortingWrapper',
})`
  padding: 0 2rem;

  span {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const SeedlingListWrapper = styled.section.attrs({
  className: 'SeedlingListWrapper',
})`
  .ContentSection {
    padding-top: 3rem;
  }
`;

export const SeedlingList = styled.ul.attrs({
  className: 'SeedlingList',
})`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 21rem;
  margin: auto;

  @media ${breakPoints.medium_break} {
    max-width: 33rem;
  }

  @media ${breakPoints.medium_break} {
    max-width: 53rem;
  }

  @media ${breakPoints.large_break} {
    max-width: 62rem;
  }
`;

export const SeedlingItem = styled.li.attrs({
  className: 'SeedlingItem',
})`
  width: calc(50% - 1.25rem);
  margin: 0 0.5rem 1.5rem;

  @media ${breakPoints.medium_break} {
    width: 31%;
    margin: 0 0.25rem 1.5rem;
  }

  @media ${breakPoints.large_break} {
    width: 9rem;
    margin: 0 0.75rem 1.5rem;
  }

  @media ${breakPoints.xLarge_break} {
    width: 10.5rem;
  }
`;

export const LinkSeedlink = styled.a.attrs({
  className: 'LinkSeedlink',
})`
  width: 100%;
  position: relative;
  display: block;

  .Image {
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    picture {
      img {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  .Image + .Image {
    display: block;
    opacity: 0;
  }

  .Text {
    margin-top: 0.5rem;
    line-height: normal;
    width: 100%;
  }

  &:hover {
    .Image {
      opacity: 0;
    }

    .Image + .Image {
      opacity: 1;
    }
  }
`;

export const ImageSeedlingThumb = styled.div.attrs({
  className: 'ImageSeedlingThumb',
})`
  position: relative;
  overflow: hidden;
  height: 6rem;

  @media ${breakPoints.medium_break} {
    height: 8rem;
  }

  @media ${breakPoints.xLarge_break} {
    height: 8.1rem;
  }
`;

export const MessageWrapper = styled.div.attrs({
  className: 'MessageWrapper',
})`
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;

  .SlimBanner {
    width: fit-content;
    padding: 0.5rem 1.25rem;
    max-width: 33rem;
    margin: auto;
  }
`;
