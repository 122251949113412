import styled from 'styled-components';

import breakPoints from 'theme/Media';

export const LifeStyleSection = styled.div.attrs({
  className: 'LifeStyleSection',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      .ContentWrapper {
        .Image {
          margin-bottom: 3rem;
        }

        .Title {
          font-size: 2.25rem;
          max-width: 20rem;
          margin: auto;
          margin-bottom: 1rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 2rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            max-width: 32rem;
            margin: 5rem auto;
          }

          .Button {
            max-width: 17rem;
            margin: auto;
            margin-top: 2rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 3.25rem;
            text-align: left;
            max-width: 25rem;
            margin: 0;
          }

          .Button {
            margin: 4rem 0 0;
          }
        }
      }
    }
  }
`;
