import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export default styled.div.attrs({
  className: 'BannerWrapper',
})`
  .SlimBanner {
    max-width: 19rem;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;

    .Text {
      font-size: 0.8125rem;
      display: flex;
      align-items: center;

      span {
        margin-left: 0.25rem;
        color: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }

    .Icon {
      width: 2.875rem;
      height: 2.875rem;
      margin-right: 0.25rem;
      flex-shrink: 0;

      svg {
        width: 78%;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .SlimBanner {
      max-width: 30rem;
    }
  }

  @media ${breakPoints.large_break} {
    .SlimBanner {
      max-width: 40rem;
    }
  }
`;
