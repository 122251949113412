import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const AssignatureSection = styled.div.attrs({
  className: 'AssignatureSection',
})`
  .ContentSection {
    .SectionInner {
      .Image {
        margin-bottom: 2rem;
      }

      .ContentWrapper {
        .Image {
          max-width: 13rem;
          margin: auto;
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .Image {
          max-width: 24.8125rem;
          max-height: 30.4375rem;
          margin: auto;
          margin-bottom: 2.5rem;
        }

        .ContentWrapper {
          .Image {
            max-width: 16.125rem;
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 10rem 2rem 8rem 2rem;

      .SectionInner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Image {
          width: calc(50% - 1.5rem);
          max-width: 30.1875rem;
          max-height: 41.875rem;
          margin-left: 0;
        }

        .ContentWrapper {
          width: calc(50% - 1.5rem);
        }
      }
    }
  }
`;
