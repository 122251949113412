import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, SquaredButton, Title } from 'elements';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { LifeStyleSection } from './LifestyleSection.styled';
import { graphql, StaticQuery } from 'gatsby';

const LifestyleSection = ({ id, title, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "7AIVoeeySivI0dGSNVxJQW" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText;
  const ctaLink = callToAction?.fields?.linkUrl;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <LifeStyleSection key={id}>
            <ContentSection animation='fadeMixUpDown'>
              <ContentWrapper>
                <GatsbyImage
                  modifiers='relativePosition'
                  image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                  alt={data?.contentfulSectionStandard?.mediaContent?.description}
                />
              </ContentWrapper>
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={title} />
                <SquaredButton label={ctaLabel} href={ctaLink} as='a' />
              </ContentWrapper>
            </ContentSection>
          </LifeStyleSection>
        );
      }}
    />
  );
};

LifestyleSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  callToAction: PropTypes.object,
};

export default LifestyleSection;
