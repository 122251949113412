import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const ImgTextSection = styled.div.attrs({
  className: 'ImgTextSection',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_1};

    .SectionInner {
      .Image {
        margin-bottom: 3rem;
      }

      .ContentWrapper {
        .Title {
          margin-bottom: 2rem;
        }

        .Text {
          p {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      background-color: ${({ theme }) => theme.farmProject.gray_1};

      .SectionInner {
        align-items: center;

        .Image {
          max-height: 30rem;
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            width: 24rem;
          }

          .Text {
            p {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 10rem 2rem;

      .SectionInner {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        justify-content: space-between;

        .Image {
          width: calc(50% - 2rem);
          margin-bottom: 0;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);
        }
      }
    }
  }
`;
