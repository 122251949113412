import React from 'react';
import { SlimBanner } from 'components';
import { Text } from 'elements';
import QuizBanner from './components/quiz-banner/QuizBanner';
import {
  MessageWrapper,
} from './SeedlingLP.styled';
import SeedlingsTitle from './components/seedlings-title/SeedlingsTitle';

const sections = {
  SEEDLINGS: "698CVZDrDMhL572avhBB67",
  TAKE_QUIZ: "5I2AzoyTYPUYOlsrk1DnIA",
  FREE_SHIPPING_PROMO: "7jApYo3wClzS0lXhOnxiFj"
}

export default {
  [sections.SEEDLINGS]: ({ id, title, body }) => (
    <SeedlingsTitle  key={id} title={title} body={body} />
  ),
  [sections.TAKE_QUIZ]: ({ id, title, body, callToAction }) => (
    <QuizBanner
      key={id}
      title={title}
      body={body}
      quizUrl={callToAction?.fields?.linkUrl}
    />
  ),
  [sections.FREE_SHIPPING_PROMO]: ({ id, body }) => (
    body && <MessageWrapper key={id}>
      <SlimBanner modifiers='lightGreen2'>
        <Text modifiers={['brandFont']} content={body} isHTML />
      </SlimBanner>
    </MessageWrapper>
  )
};