import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const JustMinutesSection = styled.div.attrs({
  className: 'JustMinutes',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      .ContentWrapper {
        .Image {
          margin-bottom: 3rem;
        }

        .Title {
          text-align: left;
          max-width: 20rem;
          margin-bottom: 1rem;
        }

        .Text {
          text-align: left;
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 26rem;
          }

          .Text {
            max-width: 32rem;
          }

          .Button {
            max-width: 17rem;
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 4rem;
            max-width: 30rem;
          }

          .Text {
            max-width: 25rem;
          }

          .Image {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
