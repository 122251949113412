import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const OurMissionStyled = styled.div.attrs({
  className: 'OurMission',
})`
  .HeroSection {
    .HeroInner {
      .Image {
        .gatsby-image-wrapper {
          picture {
            img {
              height: 105%;
              left: 32%;
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .HeroSection {
      max-height: 40rem;

      .HeroInner {
        max-width: 31.25rem;
        margin: auto;

        .Image {
          width: 100%;
          height: 100%;

          .gatsby-image-wrapper {
            picture {
              img {
                height: 115%;
                width: auto;
                left: 50%;
              }
            }
          }
        }

        .Title {
          font-size: 3.75rem;
          padding-right: 30%;
        }

        .Text {
          max-width: 21.25rem;
        }
      }
    }
    .ContentSection {
      .SectionInner {
        max-width: 31.25rem;
        margin: auto;
      }
    }
  }

  @media ${breakPoints.large_break} {
    .HeroSection {
      max-height: 41rem;

      .HeroInner {
        max-width: 62rem;

        .Image {
          height: 100%;

          .gatsby-image-wrapper {
            picture {
              img {
                top: 55%;
                height: auto;
                width: 115%;
              }
            }
          }
        }

        .Title {
          font-size: 5rem;
          max-width: 28.125rem;
          padding-right: 30%;
        }

        .Text {
          max-width: 30.375rem;
        }
      }
    }
    .ContentSection {
      .SectionInner {
        max-width: 62rem;
      }
    }
  }

  @media ${breakPoints.xLarge_break} {
    .HeroSection {
      .HeroInner {
        .Image {
          .gatsby-image-wrapper {
            picture {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`;

export const TextSection = styled.div.attrs({
  className: 'TextSection',
})`
  .ContentSection {
    .SectionInner {
      .Title {
        margin-bottom: 2rem;
      }
    }
  }
  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 10rem 2rem;

      .SectionInner {
        .Title {
          font-size: 3.75rem;
        }
      }
    }
  }
  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        max-width: 47.25rem;

        .Title {
          font-size: 5rem;
          width: 40rem;
          margin: auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;
