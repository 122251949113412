/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text } from 'elements';
import { ContentSection, SectionProvider } from 'components';

import HeroSection from './components/hero-section/HeroSection';
import InnovationSection from './components/innovation-section/InnovationSection';
import SignatureSection from './components/signature-section/SignatureSection';
import LettuceGive from './components/lettuce-give/LettuceGive';

import { OurMissionStyled, TextSection } from './OurMission.styled';

import { useStopLoading } from 'utils/hooks';

const sectionIdMap = {
  HERO: '4pDxIJQdCCjr8Y86GgyfaR',
  TEXT: '1kgRaDNla09Oq0FZ3FjCWt',
  SPLIT: '7kaclZ5okdJjmlFDO3OCjT',
  SIGNATURE: '2gs5A38rdun9cz2J7vs52t',
  LETTUCE_GIVE: '42pooB1pqiCGIPsYI9QBWu',
};

const getSectionMap = {
  [sectionIdMap.HERO]: (fields) => <HeroSection key={sectionIdMap.HERO} fields={fields} />,
  [sectionIdMap.TEXT]: (fields) => (
    <TextSection key={sectionIdMap.TEXT}>
      <ContentSection animation='fadeInUp'>
        <Title as='h3' modifiers='secondarySmall' content={fields.title} />
        <Text content={fields.body} />
      </ContentSection>
    </TextSection>
  ),
  [sectionIdMap.SPLIT]: (fields) => <InnovationSection key={sectionIdMap.SPLIT} fields={fields} />,
  [sectionIdMap.SIGNATURE]: (fields) => <SignatureSection key={sectionIdMap.SIGNATURE} fields={fields} />,
  [sectionIdMap.LETTUCE_GIVE]: (fields) => <LettuceGive key={sectionIdMap.LETTUCE_GIVE} fields={fields} />,
};

const OurMission = ({ content }) => {
  const fields = content.fields;
  useStopLoading(true);

  //<div data-nw='our-mission'/>
  return (
    <OurMissionStyled>
      <SectionProvider sectionContent={fields.sections} customSectionMap={getSectionMap} />
    </OurMissionStyled>
  );
};

OurMission.propTypes = {
  content: PropTypes.object,
};

export default OurMission;
