import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const HydroponicTechSection = styled.div.attrs({
  className: 'HydroponicTech',
})`
  position: relative;

  .ContentSection {
    padding: 5rem 2rem;

    .SectionInner {
      .ContentWrapper {
        .ImageDeviceMD,
        .ImageDeviceLG {
          display: none;
        }

        .Image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          picture {
            img {
              width: 115%;
              height: auto;
            }
          }
        }

        .Title {
          text-align: left;
          max-width: 20rem;
          margin-bottom: 1rem;
        }

        .Text {
          text-align: left;
          max-width: 20rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 2rem;

      .SectionInner {
        .ContentWrapper {
          .ImageDeviceMD {
            display: block;
          }

          .ImageDeviceSM,
          .ImageDeviceLG {
            display: none;
          }

          .Title {
            font-size: 3rem;
            max-width: 22rem;
          }

          .Text {
            max-width: 30rem;
          }

          .Image {
            picture {
              img {
                width: 115%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 10rem 2rem;

      .SectionInner {
        .ContentWrapper {
          .ImageDeviceLG {
            display: block;
          }

          .ImageDeviceSM,
          .ImageDeviceMD {
            display: none;
          }

          .Title {
            font-size: 4rem;
            max-width: 30rem;
          }

          .Text {
            max-width: 27rem;
          }

          .Image {
            picture {
              img {
                width: 120%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`;

export const FeaturesList = styled.ul.attrs({
  className: 'FeaturesList',
})`
  padding: 0;
  margin: 2rem 0 0;
  list-style: none;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${breakPoints.medium_break} {
    margin: 3rem 0 0;
  }

  @media ${breakPoints.large_break} {
    max-width: 38rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      margin-bottom: 0;
      width: auto;
    }
  }
`;

export const Feature = styled.li.attrs({
  className: 'Feature',
})`
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;

  &:before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: solid 1px ${({ theme }) => theme.farmProject.gray_3};
    border-radius: 100%;
    position: absolute;
    left: 0.5rem;
    top: 0.8rem;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 1rem;
    padding-left: 2rem;
  }
`;
