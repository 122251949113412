import React from 'react';
import PropTypes from 'prop-types';

import { useStopLoading } from 'utils/hooks';

import { SectionProvider } from 'components';
import { HomeStyled } from './Home.styled';
import { pageSpecificMap } from 'staticPages/home/SectionsRenderer';

const Home = ({ content }) => {
  useStopLoading(true);

  const fields = content.fields;

  return (
    <HomeStyled>
      <SectionProvider sectionContent={fields.sections} customSectionMap={pageSpecificMap} />
    </HomeStyled>
  );
};

Home.propTypes = {
  content: PropTypes.shape({ fields: PropTypes.object, sys: PropTypes.object }),
};

export default Home;
