import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { HeroSection } from 'components';

const Hero = ({ fields }) => {
  const imageData = graphql`
    query {
      contentfulSectionSimpleText(contentful_id: { eq: "4pDxIJQdCCjr8Y86GgyfaR" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <HeroSection
            modifiers={['inverted', 'darker']}
            heroImage={data?.contentfulSectionSimpleText?.mediaBackground?.gatsbyImageData}
            altImage={data?.contentfulSectionSimpleText?.mediaBackground?.description}
            heroTitle={fields?.title}
            heroText={fields?.body}
          />
        );
      }}
    />
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    body: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Hero;
