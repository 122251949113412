import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, Text } from 'elements';

import { ContentWrapper } from 'staticPages/ourMission/OurMission.styled';
import { AssignatureSection } from './SignatureSection.styled';

const SignatureSection = ({ fields }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "2gs5A38rdun9cz2J7vs52t" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
        mediaAccent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <AssignatureSection>
            <ContentSection animation='fadeMixUpDown'>
              <GatsbyImage
                image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                alt={data?.contentfulSectionStandard?.mediaContent?.description}
                modifiers='relativePosition'
              />
              <ContentWrapper>
                <GatsbyImage
                  image={data?.contentfulSectionStandard?.mediaAccent?.gatsbyImageData}
                  alt={data?.contentfulSectionStandard?.mediaAccent?.description}
                  modifiers='relativePosition'
                />
                <Text content={fields.body} />
              </ContentWrapper>
            </ContentSection>
          </AssignatureSection>
        );
      }}
    />
  );
};

SignatureSection.propTypes = {
  fields: PropTypes.shape({
    body: PropTypes.string,
  }),
};

export default SignatureSection;
