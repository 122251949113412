import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { ContentSection } from 'components';
import { GatsbyImage, SquaredButton, Text, Title } from 'elements';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { JustMinutesSection } from './FiveMinutesSection.styled';

const FiveMinutesSection = ({ id, title, body, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "3VCqOIL5B22LzUoYZcKskd" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText;
  const ctaLink = callToAction?.fields?.linkUrl;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <JustMinutesSection key={id}>
            <ContentSection animation='fadeMixDownUp'>
              <ContentWrapper>
                <GatsbyImage
                  modifiers='relativePosition'
                  image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                  alt={data?.contentfulSectionStandard?.mediaContent?.description}
                />
              </ContentWrapper>
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={title} />
                <Text content={body} isHTML />
                <SquaredButton label={ctaLabel} href={ctaLink} as='a' />
              </ContentWrapper>
            </ContentSection>
          </JustMinutesSection>
        );
      }}
    />
  );
};

FiveMinutesSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
};

export default FiveMinutesSection;
