/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

import { ContentSection } from 'components';
import { Text, Title } from 'elements';
import HeroBanner from './components/hero-banner/HeroBanner';
import HydroponicSection from './components/hydroponic-section/HydroponicSection';
import FiveMinutesSection from './components/five-minutes-section/FiveMinutesSection';
import LifestyleSection from './components/lifestyle-section/LifestyleSection';

import {
  ContentWrapper,
  GardeningTable,
  TableHead,
  TableBody,
  TheadCell,
  TBodyCell,
  TradGardeningSection,
  TableRow,
  HealthySection,
  PercentageWrapper,
  Percentage,
} from './HowItWorks.styled';

const sections = {
  HERO: '44em5V5aa3yOPDe9lOm4Se',
  TABLE: '6yWIhmEOorRlLxSI6edMXi',
  JUST_FIVE_MINUTES: '3VCqOIL5B22LzUoYZcKskd',
  ADVANCED_HYDROPONIC: '4f41rmkHZ6if7N0z7S0qjw',
  FOR_HEALTH: '2cVAczEHbGFKlhKNzQCfcb',
  LIFESTYLE: '7AIVoeeySivI0dGSNVxJQW',
};

export const pageSpecificMap = {
  [sections.HERO]: ({ id, title, body }) => <HeroBanner key={id} modifiers={['darker', 'inverted']} title={title} body={body} />,
  [sections.TABLE]: ({ id, title, body, percentages: tableContent }) => {
    const [header, ...rows] = tableContent || [];
    const tableHeader = header?.fields;
    return (
      <TradGardeningSection key={id}>
        <ContentSection animation='fadeInUp'>
          <ContentWrapper>
            <Title as='h3' modifiers='lineHeightNormal' content={title} />
            <Text content={body} />
          </ContentWrapper>
          <ContentWrapper>
            <GardeningTable>
              <TableHead>
                <TheadCell>
                  <Text as='span' content={tableHeader.body} />
                </TheadCell>
                <TheadCell>
                  <Text as='span' content={tableHeader.body2} />
                </TheadCell>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const id = row?.sys?.id;
                  const { title, body, body2 } = row?.fields;
                  return (
                    <TableRow key={id}>
                      <TBodyCell>
                        <Text as='span' content={title} />
                        <Text as='span' content={body} />
                        <Text as='span' content={body2} />
                      </TBodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </GardeningTable>
          </ContentWrapper>
        </ContentSection>
      </TradGardeningSection>
    );
  },
  [sections.JUST_FIVE_MINUTES]: ({ id, title, body, callToAction }) => (
    <FiveMinutesSection key={id} title={title} body={body} callToAction={callToAction} />
  ),
  [sections.ADVANCED_HYDROPONIC]: ({ id, title, body, percentages: bullets }) => (
    <HydroponicSection key={id} title={title} body={body} percentages={bullets} />
  ),
  [sections.FOR_HEALTH]: ({ id, title, body, percentages: reductions }) => {
    return (
      <HealthySection key={id}>
        <ContentSection animation='fadeInUp'>
          <ContentWrapper>
            <Title as='h3' modifiers='secondarySmall' content={title} />
            <Text content={body} />
          </ContentWrapper>
          <PercentageWrapper>
            {reductions.map((reduction) => {
              const id = reduction.sys.id;
              const { title, body } = reduction.fields;
              return (
                <Percentage key={id}>
                  <Text as='span' content={body} />
                  <Text as='span' content={title} />
                </Percentage>
              );
            })}
          </PercentageWrapper>
        </ContentSection>
      </HealthySection>
    );
  },
  [sections.LIFESTYLE]: ({ id, title, callToAction }) => <LifestyleSection key={id} title={title} callToAction={callToAction} />,
};
