import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { HeroSection } from 'components';

import breakPoints from 'theme/Media';

const HeroBanner = ({ id, title, body }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "44em5V5aa3yOPDe9lOm4Se" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const backgroundImages = data?.contentfulSectionBlocks?.mediaBackground?.map?.((image, index) => {
          return {
            image: image?.gatsbyImageData,
            alt: image?.description,
            media: index === 0 ? breakPoints.large_break : index === 1 ? breakPoints.medium_break : null,
            isDefault: index === 2,
          };
        });
        return <HeroSection key={id} modifiers={['darker', 'inverted']} heroTitle={title} heroText={body} heroImages={backgroundImages} />;
      }}
    />
  );
};

HeroBanner.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default HeroBanner;
