import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, SquaredButton, Title } from 'elements';

import { ContentWrapper } from 'staticPages/ourMission/OurMission.styled';
import { CallToActionSection } from './LettuceGive.styled';

import breakPoints from 'theme/Media';
import paths from 'constants/paths';

const LettuceGive = ({ fields }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "42pooB1pqiCGIPsYI9QBWu" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const breakPointImages = [
          {
            image: data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData,
            alt: data?.contentfulSectionStandard?.mediaContent?.description,
            media: null,
            isDefault: true,
          },
          {
            image: data?.contentfulSectionStandard?.mediaBackground?.gatsbyImageData,
            alt: data?.contentfulSectionStandard?.mediaBackground?.description,
            media: breakPoints.large_break,
            isDefault: false,
          },
        ];
        return (
          <CallToActionSection>
            <ContentSection modifiers={['wBackgroundImage', 'leftText', 'inverted']}>
              <GatsbyImage breakPointImages={breakPointImages} />
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={fields?.title} />
                <Link to={paths.blogPosts.LETTUCE_GIVE}>
                  <SquaredButton modifiers='secondary' label='LEARN MORE' />
                </Link>
              </ContentWrapper>
            </ContentSection>
          </CallToActionSection>
        );
      }}
    />
  );
};

LettuceGive.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default LettuceGive;
