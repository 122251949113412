import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { useBreakpoint, useStopLoading } from 'utils/hooks';
import sectionsRenderer from './SectionsRenderer';
import { SeedlingLandingStyled } from './SeedlingLP.styled';
import SeedlingsList from './components/seedlings-list/SeedlingsList';

const seedlingsQuery = graphql`
  query {
    allContentfulPlantBundle(filter: { weblistingEnabled: { eq: true }, isSeedlingLPEnabled: { eq: true } }) {
      nodes {
        id
        contentful_id
        name
        weblistingEnabled
        subcategory
        slug
        plantsLinks {
          id
          friendlyName
        }
        thumbnailImage {
          id
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 130, width: 168)
        }
        listingImage {
          id
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 130, width: 168)
        }
        listingImageHover {
          id
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 130, width: 168)
        }
        plantCount
      }
      totalCount
    }
    allContentfulPlantType(filter: { weblistingEnabled: { eq: true } }) {
      nodes {
        id
        contentful_id
        shortName
        weblistingEnabled
        isLimitedRelease
        slug
        category
        seasonality
        listingImage {
          id
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 130, width: 168)
        }
        friendlyName
        listingImageHover {
          id
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 130, width: 168)
        }
      }
      totalCount
    }
  }
`;

const Seedlings = ({ content }) => {
  const size = useBreakpoint();
  const isDesktop = ['XL', 'LG'].includes(size);
  useStopLoading(true);

  const fields = content.fields;
  const sectionsData = fields.sections.map((e) => ({ ...e.fields, id: e.sys.id, isDesktop, size }));

  const renderSection = (sectionData) => {
    const section = sectionsRenderer[sectionData.id];
    return section && section(sectionData);
  };

  return (
    <SeedlingLandingStyled>
      {sectionsData.map(renderSection)}
      <StaticQuery
        query={seedlingsQuery}
        render={(data) => {
          return <SeedlingsList seedlingsContent={data} />;
        }}
      />
    </SeedlingLandingStyled>
  );
};

Seedlings.propTypes = {
  content: PropTypes.object,
};

export default Seedlings;
