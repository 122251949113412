import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const CallToActionSection = styled.div.attrs({
  className: 'CallToActionSection',
})`
  .ContentSection {
    padding: 10.5rem 2rem;

    .SectionInner {
      .ContentWrapper {
        .Title {
          margin-bottom: 2rem;
          width: 100%;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
        }
      }
    }

    .ImageDeviceLG {
      display: none;
    }

    .SectionInner .Image {
      width: 110%;
      height: 110%;

      picture {
        img {
          width: 150%;
          height: auto;
          top: 65%;
        }
      }
    }
  }

  @media ${breakPoints.small_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.75rem;
            max-width: 23.75rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 12.5rem 2rem;

      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 4rem;
            max-width: 32.25rem;
            line-height: 1.25;
          }

          .Button {
            max-width: 17rem;
          }
        }

        .ImageDeviceLG {
          display: block;
        }

        .ImageDeviceSM {
          display: none;
        }

        .Image {
          picture {
            img {
              width: 115%;
              top: 50%;
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.xLarge_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 5rem;
            max-width: 40rem;
          }
        }
        .Image {
          top: 50%;
          width: 100%;
        }
      }
    }
  }
`;
