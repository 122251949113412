import React from 'react';
import { navigate } from 'gatsby';

import { useBreakpoint } from 'utils/hooks';

import { SlimBanner } from 'components';
import { Icon, Text } from 'elements';
import BannerWrapper from './QuizBanner.styled';
import BannerIcon from 'elements/icon/assets/seedling-question-icon.svg';

export default ({ title, body, quizUrl }) => {
  const size = useBreakpoint();
  const isDesktop = ['XL', 'LG'].includes(size);
  const text = `${title}${isDesktop ? ` ${body}` : ''}`;

  const goToUrl = () => {
    navigate(quizUrl);
  };

  return (
    <BannerWrapper>
      <SlimBanner onClick={goToUrl} modifiers='brandColorFont'>
        <Icon>
          <BannerIcon />
        </Icon>
        <Text modifiers={['brandFont', 'noLineHeight']} content={text} isHTML />
      </SlimBanner>
    </BannerWrapper>
  );
};
