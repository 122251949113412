import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, Text, Title } from 'elements';

import { ContentWrapper } from 'staticPages/ourMission/OurMission.styled';
import { ImgTextSection } from './InnovationSection.styled';

const InnovationSection = ({ fields }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "7kaclZ5okdJjmlFDO3OCjT" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <ImgTextSection>
            <ContentSection modifiers={['leftText']} animation='fadeMixDownUp'>
              <GatsbyImage
                image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                alt={data?.contentfulSectionStandard?.mediaContent?.description}
                modifiers='relativePosition'
              />
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={fields?.title} />
                <Text isHTML content={fields?.body} />
              </ContentWrapper>
            </ContentSection>
          </ImgTextSection>
        );
      }}
    />
  );
};

InnovationSection.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

export default InnovationSection;
